@import 'variables';

.button,
.btn {
  min-height: 3rem;
  padding: 1rem 0.5rem;
  border-radius: 4px;
  color: $background-color;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  text-align: center;
  background: $primary-main;
}
