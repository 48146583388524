@import '../src/assets/common/variables';

$weight: (
  regular: 300,
  bold: 900,
);

html {
  line-height: $baseLineHeight;
  font-family: $font;
}

body {
  font-family: $font;
  font-size: 16px;
  color: $text-main;
}

p {
  margin-bottom: $baseLineHeight;
}

p + p {
  margin-top: 0;
}

span {

}


h1, h2, h3, h4 {
  font-family: $font;
  margin: 0 0 $baseFontSize 0;
  color: $text-header;
}

h2, h3, h4 {
  font-weight: map_get($weight, bold);
}

h1 {
  font-family: $font;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: map_get($weight, bold);
}

h2 {
  font-size: 1.8rem;
  line-height: 1.333;
}

h3 {
  font-size: 1.777rem;
  line-height: 1.777rem * 1.333;
}

h4 {
  font-size: 1.333rem;
  line-height: 1.333rem * 1.333;
}

small,
.hint {
  font-size: 0.75rem;
  font-weight: map_get($weight, light);
}

a {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s color ease-in-out;
  color: $primary-main;

  &:hover {
    color: $primary-active;
    transition: 0.3s color ease-in-out;
  }
}

h1 + h2,
h1 + h3,
h1 + h4,
h2 + h3,
h2 + h4,
h3 + h4 {
  margin-top: 0;
}

p + img,
p + picture {
  margin-top: 1rem;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: map_get($weight, bold);
}

.smaller_font {
  font-size: $baseFontSize * 0.75;
}
