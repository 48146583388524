@import '../src/assets/common/mq';
@import '../../../assets/common/variables';
@import '../src/assets/common/layout';

:host {
  .cnt {
    flex-direction: row;
  }

  h2 {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
}

.wrapper {
}

.contacts {
  padding-top: 7rem;
  padding-bottom: 5rem;

  &.mobile {
    padding-top: 2rem;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.illustration {
  opacity: 0.85;
  height: 550px;
  width: 500px;
  background-repeat: no-repeat;
  margin-right: 5rem;
}


p {
  margin-bottom: 1rem;
}

h3 {
  margin-top: 2rem;
}

//.inner {
//  &.mobile {
//    padding-left: 20px;
//    padding-right: 20px;
//  }
//}

.socials {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  width: 50%;
  flex-grow: 0;
  margin-bottom: 2rem;
}

.socials_item {
  width: 50px;
  height: 50px;
  margin-right: 1.5rem;

  a {
    display: flex;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: auto;
      fill: #ffffff;
      transition: 0.25s fill ease;
    }

    &:hover {
      svg {
        //fill: map_get($colors, secondary);
      }
    }
  }
}

.write_us {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 0;
  margin-bottom: 2rem;
}

.write_us_item {
  margin-right: 1.5rem;
  margin-top: 1rem;
}
