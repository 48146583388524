/*
 * Global Styles
*/

@import '../src/assets/common/_normalize.scss';
@import 'assets/common/variables';
@import '../src/assets/common/_mq.scss';
@import '../src/assets/common/_scaffolding.scss';
@import '../src/assets/common/_typography.scss';
@import '../src/assets/common/_layout.scss';
@import '../src/assets/common/_buttons.scss';

//components
@import 'app/pages/welcome/welcome.component.scss';

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}
