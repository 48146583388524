@import '../src/assets/common/variables';

html {
  font-family: $font;
}

* {
  box-sizing: border-box;
}


body {
  background-color: $background-color;
}

// reset buttons in input type number
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  background: none;

}

ul {
  padding: 0;
  margin: 0;
}


.btn {
}

.input__wrp {
}

.breadcrumbs {
}

.page_title {
}

.visually_hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  opacity: 0;
}

.text_link {
  width: auto;
  text-align: left;
}

li {
  margin-bottom: 12px;
}

.flex__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex__col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
