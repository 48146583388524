@import "./mq";

.cnt {
  @include min-screen(1100px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1100px;
    margin: 0 auto;
  }
}

.wrapper {
  width: 100%;
}
