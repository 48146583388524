// colors
$background-color: #ececec;
$primary-main: #00abb8;
$primary-active: #30dbe8;
$primary-disabled: #888888;

$primary-contrast: #eeeeee;

// text colors
$text-main: #1f1f1f;
$text-header: #3f3f3f;

$boxShadow: #{'0 2px 5px 0 rgba(0, 0, 0, 0.04), 0 5px 5px 0 rgba(0, 0, 0, 0.02), 0 3px 1px -2px rgba(0,0,0,0.05);'};

$baseFontSize: 1rem;
$baseLineHeight: $baseFontSize * 1.333;
$font: #{"'Roboto Slab', serif"};
$transition: 0.33s all ease-in-out;
